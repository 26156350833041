import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Создаём контекст
export const AuthContext = createContext();

// Провайдер контекста
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('jwt_token') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
  });

  // Функция для логина
  const login = (token, user) => {
    localStorage.setItem('jwt_token', token);
    localStorage.setItem('user', JSON.stringify(user));
    setAuth({ token, user });
  };

  // Функция для логаута
  const logout = () => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('user');
    setAuth({ token: null, user: null });
  };

  // Добавление токена к каждому запросу
  useEffect(() => {
    if (auth.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [auth.token]);

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
