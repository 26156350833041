// src/components/Rooms/JoinRoom.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const JoinRoom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [roomId, setRoomId] = useState('');
  const [error, setError] = useState(null);

  const onChange = (e) => setRoomId(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // Предполагается, что ваш бэкенд имеет маршрут для присоединения к комнате по ID
      await axios.post(`/api/rooms/${roomId}/join`);
      navigate('/rooms');
    } catch (err) {
      setError(err.response.data.message || 'Failed to join room');
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-6">
        <h2>{t('join_room')}</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="roomId">Room ID</label>
            <input
              type="text"
              className="form-control"
              id="roomId"
              name="roomId"
              value={roomId}
              onChange={onChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {t('submit')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default JoinRoom;
