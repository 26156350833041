import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <div>
      <h2>Dashboard</h2>
      <p>Welcome, {auth.user.username}!</p>
      <Link to="/rooms/create" className="btn btn-primary mr-2">
        {t('create_room')}
      </Link>
      <Link to="/rooms/join" className="btn btn-secondary">
        {t('join_room')}
      </Link>
      <hr />
      <Link to="/rooms" className="btn btn-info">
        View All Rooms
      </Link>
    </div>
  );
};

export default Dashboard;
