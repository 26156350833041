// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <h1>{t('welcome')}</h1>
      <p>
        <Link to="/login" className="btn btn-primary mr-2">
          {t('login')}
        </Link>
        <Link to="/register" className="btn btn-secondary">
          {t('register')}
        </Link>
      </p>
    </div>
  );
};

export default Home;
