// src/components/Rooms/CreateRoom.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreateRoom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    language: '',
    proficiencyLevel: '',
    maxParticipants: '',
    topic: '',
  });

  const [error, setError] = useState(null);

  const { name, language, proficiencyLevel, maxParticipants, topic } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/rooms/create', formData);
      navigate('/rooms');
    } catch (err) {
      setError(err.response.data.message || 'Failed to create room');
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-8">
        <h2>{t('create_room')}</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="name">{t('room_name')}</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={name}
              onChange={onChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="language">{t('language')}</label>
            <input
              type="text"
              className="form-control"
              id="language"
              name="language"
              value={language}
              onChange={onChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="proficiencyLevel">{t('proficiency_level')}</label>
            <input
              type="text"
              className="form-control"
              id="proficiencyLevel"
              name="proficiencyLevel"
              value={proficiencyLevel}
              onChange={onChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="maxParticipants">{t('max_participants')}</label>
            <input
              type="number"
              className="form-control"
              id="maxParticipants"
              name="maxParticipants"
              value={maxParticipants}
              onChange={onChange}
              required
              min="1"
            />
          </div>
          <div className="form-group">
            <label htmlFor="topic">{t('topic')}</label>
            <input
              type="text"
              className="form-control"
              id="topic"
              name="topic"
              value={topic}
              onChange={onChange}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {t('submit')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateRoom;
