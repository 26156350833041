// src/components/Rooms/RoomList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const RoomList = () => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const res = await axios.get('/api/rooms');
        setRooms(res.data.rooms);
      } catch (err) {
        setError(err.response.data.message || 'Failed to fetch rooms');
      }
    };

    fetchRooms();
  }, []);

  return (
    <div>
      <h2>Available Rooms</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {rooms.length === 0 ? (
        <p>{t('no_rooms_found')}</p>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>{t('room_name')}</th>
              <th>{t('language')}</th>
              <th>{t('proficiency_level')}</th>
              <th>{t('max_participants')}</th>
              <th>{t('topic')}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rooms.map((room) => (
              <tr key={room.id}>
                <td>{room.id}</td>
                <td>{room.name}</td>
                <td>{room.language}</td>
                <td>{room.proficiencyLevel}</td>
                <td>{room.maxParticipants}</td>
                <td>{room.topic}</td>
                <td>
                  <Link to={`/rooms/${room.id}`} className="btn btn-info btn-sm">
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RoomList;
