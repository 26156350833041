// src/components/Rooms/RoomDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RoomDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [room, setRoom] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRoom = async () => {
      try {
        const res = await axios.get(`/api/rooms/${id}`);
        setRoom(res.data.room);
      } catch (err) {
        setError(err.response.data.message || 'Failed to fetch room');
      }
    };

    fetchRoom();
  }, [id]);

  const handleJoin = async () => {
    try {
      await axios.post(`/api/rooms/${id}/join`);
      navigate('/rooms');
    } catch (err) {
      setError(err.response.data.message || 'Failed to join room');
    }
  };

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!room) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{room.name}</h2>
      <p>{t('language')}: {room.language}</p>
      <p>{t('proficiency_level')}: {room.proficiencyLevel}</p>
      <p>{t('max_participants')}: {room.maxParticipants}</p>
      <p>{t('topic')}: {room.topic}</p>
      <button className="btn btn-primary" onClick={handleJoin}>
        {t('join_room')}
      </button>
    </div>
  );
};

export default RoomDetails;
