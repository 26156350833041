// src/components/Layout/Navbar.js
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          {t('welcome')}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            {!auth.token ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    {t('login')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/register">
                    {t('register')}
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={handleLogout}>
                    {t('logout')}
                  </button>
                </li>
              </>
            )}
            {/* Переключение языка */}
            <li className="nav-item dropdown">
              <button
                className="btn btn-link nav-link dropdown-toggle"
                id="languageDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t('language')}
              </button>
              <div className="dropdown-menu" aria-labelledby="languageDropdown">
                <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                  English
                </button>
                <button className="dropdown-item" onClick={() => changeLanguage('ru')}>
                  Русский
                </button>
                <button className="dropdown-item" onClick={() => changeLanguage('de')}>
                  Deutsch
                </button>
                <button className="dropdown-item" onClick={() => changeLanguage('ua')}>
                  Українська
                </button>
                <button className="dropdown-item" onClick={() => changeLanguage('ar')}>
                  العربية
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
