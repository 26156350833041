// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

// Импорт компонентов
import Navbar from './components/Layout/Navbar';
import Home from './pages/Home';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './pages/Dashboard';
import CreateRoom from './components/Rooms/CreateRoom';
import JoinRoom from './components/Rooms/JoinRoom';
import RoomList from './components/Rooms/RoomList';
import RoomDetails from './components/Rooms/RoomDetails'; // Если есть
import NotFound from './pages/NotFound';
import PrivateRoute from './components/Layout/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <div className="container mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/rooms/create" 
              element={
                <PrivateRoute>
                  <CreateRoom />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/rooms/join" 
              element={
                <PrivateRoute>
                  <JoinRoom />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/rooms" 
              element={
                <PrivateRoute>
                  <RoomList />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/rooms/:id" 
              element={
                <PrivateRoute>
                  <RoomDetails />
                </PrivateRoute>
              } 
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
